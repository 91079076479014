import BaseService from './baseService'

class HouseCallMapService extends BaseService {
  get url () {
    return 'v1/house-call-maps'
  }

  createModel () {
    return {
      examination: null,
      map: '',
      doctors: [],
      clinics: []
    }
  }
}

export default new HouseCallMapService()

