<template>
  <div class="list-page">
    <div class="main-container">
      <h1 class="title">Вызов на дом</h1>

      <el-row type="flex" justify="space-between" class="actions_bar">
        <el-col :md="7" :xs="20" class="actions_bar__search">
          <el-input placeholder="Поиск" v-model="query.search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="3" style="" class="actions_bar__create">
          <router-link :to="{ name: 'HouseCallMap', params: { id: 'new' }}">
            <el-button type="success" icon="el-icon-document">Создать</el-button>
          </router-link>
        </el-col>
      </el-row>

      <el-table :data="items" style="width: 100%;" class="list-items"
            v-loading="loading" border fit highlight-current-row
      >
        <el-table-column
            label="Услуга"
            prop="service.name"
        >
        </el-table-column>

        <el-table-column
            label="Вид приема ID"
            prop="examination.id"
            width="120"
        >
        </el-table-column>

        <el-table-column
            label="Вид приема"
            prop="examination.name"
        >
        </el-table-column>

        <el-table-column
            label="Клиники"
        >
          <template slot-scope="scope">
            <el-tag type="info" v-for="c in scope.row.clinics" v-text="c.name"></el-tag>
          </template>
        </el-table-column>

        <el-table-column
            label="Доктора"
        >
          <template slot-scope="scope">
            <el-tag type="info" v-for="d in scope.row.doctors" v-text="d.full_name"></el-tag>
          </template>
        </el-table-column>

        <el-table-column align="right"
           width="120"
        >
          <template slot-scope="scope">
            <router-link :to="{ name: 'HouseCallMap', params: { id: scope.row.uuid }}">
              <el-button type="primary" size="mini" icon="el-icon-edit"></el-button>
            </router-link>
            <el-button type="danger" size="mini" icon="el-icon-delete"
                   @click="remove(scope.row, scope.$index)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination background
        layout="prev, pager, next"
        :page-count="pagination.total"
        :current-page="pagination.current"
        @current-change="changePage"
        :hide-on-single-page="pagination.total <= 1"
        :page-size="pagination.limit"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import service from '../../services/house-call-maps'
import itemListViewMixin from '../../mixins/itemListView'

export default {
  mixins: [itemListViewMixin(service, 'maps', true)],
  methods: {
    remove (map, $index) {
      this.removeConfirm(map, $index, `Вы действительно хотите удалить?`)
    }
  }
}
</script>
